@import "@/assets/scss/style.scss";

.client-top-bar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 0 5%;
}
.left-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    .product-info {
        padding: 0 1.5rem;
        .path-before {
            font-size: 0.96rem;
            font-weight: 400;
            color: $grey-7;
        }
        .path-current {
            font-size: 0.96rem;
            font-weight: 450;
            color: #262626;
        }
        .product-name {
            font-size: 1.125rem;
            font-weight: bold;
            color: $grey-9;
        }
    }
}

.right-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}
.misc {
    padding: 0 8px;
}
.icon-color {
    color: $grey-9;
}
